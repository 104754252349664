import { BEP20PairABI } from "./stats/abi";
import { RoundPrice } from "./utils";
export class Milestones {
  milesstonesScrollElem: HTMLDivElement;
  bitcoinPriceItem: HTMLElement;
  bitcoinPriceText: HTMLElement;
  itemHtmlElements: NodeListOf<HTMLDivElement>;
  itemValues: number[] = [];

  BITCOIN_PAIR_ADDRESS = "0x7561EEe90e24F3b348E1087A005F78B4c8453524";

  constructor(
    private price: number,
    private web3: any,
    private BNBPrice: number
  ) {
    this.init();
  }

  async init() {
    const BTCPriceRaw = await this.getBitcoinPrice();

    // query BTC price item and text elements
    this.bitcoinPriceItem = document.querySelector(".milestones-item-1");
    this.bitcoinPriceText = this.bitcoinPriceItem.querySelector(
      ".milestones-item-price-bitcoin"
    );

    // set data for btc
    this.bitcoinPriceItem.dataset.value = BTCPriceRaw.toString();

    // round price for display and set to text element
    const BTCRounded = RoundPrice(BTCPriceRaw);
    this.bitcoinPriceText.textContent = "$" + BTCRounded;

    // query milestonescroll element and all items (includes the newly updated bitcoin price element)
    this.milesstonesScrollElem = document.querySelector(".milestones-scroll");
    this.itemHtmlElements = document.querySelectorAll(".milestones-item");

    //   loop over item list to get values from data-value and set inActive class
    this.itemHtmlElements.forEach((elem, i) => {
      // remove commas and parse value
      const value = parseInt(elem.dataset.value.replace(/,/g, ""));
      this.itemValues.push(value);

      //   add class
      if (value <= this.price) {
        elem.classList.add("active");
      }
    });

    const scrollWidth = this.milesstonesScrollElem.scrollWidth;
    const clientWidth = this.milesstonesScrollElem.clientWidth;

    // ---Compute total percentage of filled bar---

    //   find index closest to price
    let maxTier = this.itemValues.findIndex((i) => i > this.price);
    if (maxTier === -1) maxTier = this.itemValues.length - 1;

    const minTier = maxTier - 1 >= 0 ? maxTier - 1 : 0;

    let totalPercent: number;

    if (maxTier === 0 && minTier === 0) {
      totalPercent = 0;
    } else {
      const basePercent = minTier / (this.itemValues.length - 1);

      const tierPercent =
        this.price /
        (this.itemValues[maxTier] - this.itemValues[minTier]) /
        (this.itemValues.length - 1);
      totalPercent = Math.min(basePercent + tierPercent, 1);
    }

    // update progess bar css width to appropriate percentage
    this.milesstonesScrollElem.style.setProperty(
      "--barFilledPercent",
      totalPercent * 80 + "%"
    );

    // try to center marker by scrolling container
    this.milesstonesScrollElem.scrollLeft =
      (totalPercent * .80) * scrollWidth - clientWidth / 2 + 40;
  }

  async getBitcoinPrice() {
    const pairContract = await new this.web3.eth.Contract(
      BEP20PairABI,
      this.BITCOIN_PAIR_ADDRESS
    );

    const reserves = await pairContract.methods.getReserves().call();
    const price =
      (parseFloat(reserves._reserve1) / parseFloat(reserves._reserve0)) *
      this.BNBPrice;
    return price;
  }
}
