export class Buy {
  copyAddressBtn: HTMLElement;
  tokenAddress: HTMLElement;

  popupOpenBtn: HTMLElement;
  popupCloseBtn: HTMLElement;
  popup: HTMLElement;
  modal: HTMLElement;
  outsideClickListener;
  constructor() {
    this.init();
  }

  init() {
    // query all required elements
    this.popupOpenBtn = document.querySelector<HTMLElement>(
      ".buy-grid-help-buttons-details"
    );
    this.popupCloseBtn = document.querySelector<HTMLElement>(
      ".buy-grid-help-popup-modal-close"
    );
    this.popup = document.querySelector<HTMLElement>(".buy-grid-help-popup");
    this.modal = this.popup.querySelector<HTMLElement>(
      ".buy-grid-help-popup-modal"
    );

    this.copyAddressBtn = document.querySelector<HTMLElement>(
      ".buy-grid-item-description-copyAddress"
    );
    this.tokenAddress = this.copyAddressBtn.querySelector<HTMLSpanElement>(
      ".token-address"
    );

    // toggle popup
    this.popupOpenBtn.addEventListener("click", () => {
      this.togglePopup(true);
      setTimeout(() => {
        // listener function that sees if clicked element is inside current element
        this.outsideClickListener = ((e: MouseEvent) => {
          if (!this.modal.contains(e.target as HTMLElement)) {
            // remove listener
            document.removeEventListener("click", this.outsideClickListener);
            // exit
            this.togglePopup(false);
          }
        }).bind(this);

        // add listener
        document.addEventListener("click", this.outsideClickListener);
      }, 0);
    });
    this.popupCloseBtn.addEventListener("click", () => {
      this.togglePopup(false);
      // remove listener
      document.removeEventListener("click", this.outsideClickListener);
    });

    // copy address on click
    this.copyAddressBtn.addEventListener("click", () => {
      // reset address class and animation state to play animation
      this.copyAddressBtn.classList.remove("copySuccess");
      this.copyAddressBtn.style.animation = "none";
      this.copyAddressBtn.offsetHeight; /* trigger reflow */
      this.copyAddressBtn.style.animation = null;
      this.copyAddressBtn.classList.add("copySuccess");

      // copy text to clipboard
      this.copyToClipboard(this.tokenAddress.textContent);
    });
  }

  togglePopup(state: boolean) {
    if (state) {
      document.body.style.overflowY = "hidden";
      this.popup.classList.toggle("active", true);
    } else {
      document.body.style.overflowY = "auto";
      this.popup.classList.toggle("active", false);
    }
  }

  copyToClipboard(str: string) {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }
}
