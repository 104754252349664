import { PANCAKE_ABI, BEP20PairABI, ONE_TOKEN_ABI } from "./abi";
import { RoundPrice } from "../utils";

const BNBAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
export class Stats {
  daysElem: HTMLDivElement;
  priceElem: HTMLDivElement;
  rewardsElem: HTMLDivElement;
  ONE_TOKEN_ADDRESS = "0xBB994E80E2eDc45dCe9065bda73ADc7E9337b64F";
  ONE_TOKEN_BNB_PairAddress = "0x98eeda2abe0a09c6111182c210907d3bf6098efe";
  tokenContract: any;

  constructor(private web3: any, private BNBPrice: number) {
    this.init();
  }

  async init() {
    this.daysElem = document.querySelector(".coinStats-item-text-days");

    this.priceElem = document.querySelector(".coinStats-item-text-price");

    this.rewardsElem = document.querySelector(".coinStats-item-text-reward");

    this.tokenContract = await new this.web3.eth.Contract(
      ONE_TOKEN_ABI,
      this.ONE_TOKEN_ADDRESS
    );

    // set days elem
    this.updateDaysText();
    // set rewards
    this.getRewards();
  }

  async getRewards() {
    const feesRaw = await this.tokenContract.methods.totalFees().call();
    const fees = feesRaw * 10 ** -18;
    const oldFees = 0.244;
    const currentFees = oldFees + fees;
    this.rewardsElem.textContent = currentFees.toString().substring(0, 5);
  }

  async getO1TPrice() {
    // get O1T price
    const pairContract = await new this.web3.eth.Contract(
      BEP20PairABI,
      this.ONE_TOKEN_BNB_PairAddress
    );

    const reserves = await pairContract.methods.getReserves().call();

    const price =
      (parseFloat(reserves._reserve0) / parseFloat(reserves._reserve1)) *
      this.BNBPrice;

    // update price in html element
    this.updatePriceText(price);

    return price;
  }

  updatePriceText(price: number) {
    const rounded = RoundPrice(price);

    this.priceElem.textContent = "$" + rounded;
  }

  updateDaysText() {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDateMillis = new Date(2021, 4, 18).setHours(0, 0, 0); //year, month(starts at 0), day
    const secondDateMillis = new Date().setHours(0, 0, 0); //today
    const diffDays = Math.round(
      Math.abs((firstDateMillis - secondDateMillis) / oneDay)
    );

    // update html element
    this.daysElem.textContent = diffDays.toString();
  }
}
