import { BEP20PairABI } from "./stats/abi";
const roundScale = {
  1: { symbol: "", digits: 1, decimalPlace: null },
  2: { symbol: "", digits: 2, decimalPlace: null },
  3: { symbol: "", digits: 3, decimalPlace: null },
  4: { symbol: "K", digits: 3, decimalPlace: 1 },
  5: { symbol: "K", digits: 3, decimalPlace: 2 },
  6: { symbol: "K", digits: 3, decimalPlace: null },
  7: { symbol: "M", digits: 3, decimalPlace: 1 },
  8: { symbol: "M", digits: 3, decimalPlace: 2 },
  9: { symbol: "M", digits: 3, decimalPlace: null },
  10: { symbol: "B", digits: 3, decimalPlace: 1 },
  11: { symbol: "B", digits: 3, decimalPlace: 2 }
};

export function RoundPrice(price: number): string {
  const priceString = Math.round(price).toString();
  const endChar = roundScale[priceString.length].digits;
  let priceTextRounded = priceString.slice(0, endChar);
  const decimalPlace = roundScale[priceString.length].decimalPlace;
  if (decimalPlace) {
    priceTextRounded =
      priceTextRounded.substring(0, decimalPlace) +
      "." +
      priceTextRounded.substring(decimalPlace);
  }
  priceTextRounded += roundScale[priceString.length].symbol;

  return priceTextRounded;
}

export async function GetBNBPrice(web3) {
  // get price of BNB via BUSD pair
  const BNB_BUSDPairAddress = "0x1B96B92314C44b159149f7E0303511fB2Fc4774f";
  const pairContract = await new web3.eth.Contract(
    BEP20PairABI,
    BNB_BUSDPairAddress
  );

  const reserves = await pairContract.methods.getReserves().call();
  return parseFloat(reserves._reserve1) / parseFloat(reserves._reserve0);
}
