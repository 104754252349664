export const VERTEX_SHADER = `attribute float scale;
      attribute vec4 colors;
      varying vec4 vColor;
      void main() {

      	vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
       vColor = colors;
        gl_PointSize = clamp(scale, 0.0, 1000.0) ; 
          gl_Position = projectionMatrix * mvPosition;
      }
    `;
