import "regenerator-runtime/runtime";
import { Hero } from "./hero/hero";
import { Stats } from "./stats/stats";
import { Milestones } from "./milestones";
import { Buy } from "./buy";
import { News } from "./news";
import { GetBNBPrice } from "./utils";

declare const AOS;
const Web3JS = require("web3");

(async () => {
  // Init AOS
  AOS.init({ once: true, disable: "phone" });

  // Init web3
  const web3 = new Web3JS("https://bsc-dataseed1.binance.org:443");

  // Hero Section Manager
  const hero = new Hero();

  // Get BNB Price for Stats and Milestones sections
  const BNBPrice = await GetBNBPrice(web3);

  //Stats Section Manager
  const stats = new Stats(web3, BNBPrice);
  const O1TPrice = await stats.getO1TPrice();

  // Milestone Manager
  const milestones = new Milestones(O1TPrice, web3, BNBPrice);

  // Buy Manager
  const buy = new Buy();

  // News Manager
  const news = new News();
})();
