declare const MediumWidget: any;

export class News {
  twitterBtn: HTMLButtonElement;
  mediumBtn: HTMLButtonElement;
  dataFrame: HTMLElement;
  twitterFrame: HTMLElement;
  mediumFrame: HTMLElement;

  constructor() {
    this.init();
  }

  init() {
    this.dataFrame = document.querySelector(".news-dataFrame");

    this.twitterBtn = document.querySelector(".news-selectors-twitter");
    this.mediumBtn = document.querySelector(".news-selectors-medium");

    this.twitterFrame = document.querySelector(".news-dataFrame-twitter");
    this.mediumFrame = document.querySelector(".news-dataFrame-medium");

    this.twitterBtn.addEventListener("click", () => {
      this.setActive("twitter");
    });
    this.mediumBtn.addEventListener("click", () => {
      this.setActive("medium");
    });

    this.setActive("twitter");

    this.initMedium();
  }

  setActive(state: "twitter" | "medium") {
    if (state === "twitter") {
      // hide medium
      this.mediumBtn.classList.toggle("active", false);
      this.mediumFrame.classList.toggle("hide", true);

      // show twitter
      this.twitterBtn.classList.toggle("active", true);
      this.twitterFrame.classList.toggle("hide", false);
    } else {
      // hide twitter
      this.twitterBtn.classList.toggle("active", false);
      this.twitterFrame.classList.toggle("hide", true);

      // show medium
      this.mediumBtn.classList.toggle("active", true);
      this.mediumFrame.classList.toggle("hide", false);
    }
    this.dataFrame.scrollTop = 0;
  }

  initMedium() {
    MediumWidget.Init({
      renderTo: "#medium-widget",
      params: {
        resource: "https://medium.com/",
        postsPerLine: 1,
        limit: 3,
        picture: "big",
        fields: ["description", "author", "claps", "publishAt"],
        ratio: "landscape"
      }
    });
  }
}
