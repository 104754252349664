import * as THREE from "three";
import { VERTEX_SHADER } from "./vertexShader";
import { FRAGMENT_SHADER } from "./fragmentShader";

export class Hero {
  heroCont: HTMLDivElement;
  canvas: HTMLCanvasElement;
  clock: THREE.Clock;

  camera: THREE.PerspectiveCamera;
  scene: THREE.Scene;
  renderer: THREE.WebGLRenderer;
  time: number = 0;
  sizeOffsets: Float32Array;
  particles: THREE.Points;
  numParticles: number;
  offsetState: 300 | 200;
  //  particle variables
  SPACING = 30;
  AMOUNTX = 60;
  AMOUNTY = 60;
  OFFSET_Y_HEIGHT: 300 | 200;
  MAX_POINT_SIZE = 70 * window.devicePixelRatio;
  MIN_POINT_SIZE = 0.1;
  RIPPLE_SPEED = 4;
  COLOR_ONE = { r: 50 / 255, g: 50 / 255, b: 50 / 255 };
  COLOR_TWO = { r: 50 / 255, g: 50 / 255, b: 50 / 255 };
  MAX_OPACITY = 0.2;

  constructor() {
    this.init();
    this.animate();
  }

  init() {
    // ---query html elements---
    this.heroCont = document.querySelector(".hero");
    this.canvas = document.querySelector(".hero-threejs");

    // ---setup three js---
    // 1.) renderer
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      alpha: true
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(
      this.heroCont.clientWidth,
      this.heroCont.clientHeight
    );
    //2.) add camera
    this.camera = new THREE.PerspectiveCamera(
      75,
      this.heroCont.clientWidth / this.heroCont.clientHeight,
      1,
      10000
    );
    this.camera.position.z = 1000;

    //3.) add scene
    this.scene = new THREE.Scene();

    //4.) add clock
    this.clock = new THREE.Clock();

    // ---create particle values---
    const { positions, scales, colors } = this.createParticleValues();

    // ---create geometry and set attributes---
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute("scale", new THREE.BufferAttribute(scales, 1));
    geometry.setAttribute("colors", new THREE.BufferAttribute(colors, 4));

    // ---create material and assign to particles geometry---
    const material = new THREE.ShaderMaterial({
      vertexShader: VERTEX_SHADER,
      fragmentShader: FRAGMENT_SHADER,
      transparent: true
    });

    this.particles = new THREE.Points(geometry, material);

    //   ---Translate particles to center of screen and add to scene

    this.OFFSET_Y_HEIGHT = this.heroCont.clientWidth <= 550 ? 300 : 200;

    this.particles.geometry.translate(
      -(this.AMOUNTX - 1) * this.SPACING * 0.5,
      this.AMOUNTY * this.SPACING * 0.5 + this.OFFSET_Y_HEIGHT,
      0
    );
    this.scene.add(this.particles);

    // ---Add resize listener for window---
    // window.addEventListener("resize", this.onWindowResize.bind(this));
  }

  createParticleValues(): {
    positions: Float32Array;
    scales: Float32Array;
    colors: Float32Array;
  } {
    this.numParticles = this.AMOUNTX * this.AMOUNTY;
    this.sizeOffsets = new Float32Array(this.numParticles);
    const positions = new Float32Array(this.numParticles * 3);
    const scales = new Float32Array(this.numParticles);
    const colors = new Float32Array(this.numParticles * 4);

    let i = 0;
    let c = 0;
    const maxToCenter = this.SPACING * ((this.AMOUNTX - 1) / 2);

    for (let j = 0; j < this.numParticles; j++) {
      // set postions
      positions[i] = this.SPACING * (j % this.AMOUNTX); // x
      positions[i + 1] = -this.SPACING * ~~(j / this.AMOUNTX); // y
      positions[i + 2] = 0; // z

      //get distance from center to current point
      const distToCenter = Math.hypot(
        this.SPACING * ((this.AMOUNTX - 1) / 2) - positions[i],
        -this.SPACING * ((this.AMOUNTY - 1) / 2) - positions[i + 1]
      );

      // set size offset
      this.sizeOffsets[j] = (Math.PI / (distToCenter + 0.001)) * 500;

      // set colors
      colors[c] = this.numMap(
        distToCenter,
        0,
        maxToCenter,
        this.COLOR_ONE.r,
        this.COLOR_TWO.r
      );
      colors[c + 1] = this.numMap(
        distToCenter,
        0,
        maxToCenter,
        this.COLOR_ONE.g,
        this.COLOR_TWO.g
      );
      colors[c + 2] = this.numMap(
        distToCenter,
        0,
        maxToCenter,
        this.COLOR_ONE.b,
        this.COLOR_TWO.b
      );
      colors[c + 3] = this.numMap(
        distToCenter - 5 * this.SPACING,
        0,
        maxToCenter,
        this.MAX_OPACITY,
        0
      );

      // set scales
      scales[i] = 0;

      // increment color and postion interables
      i += 3;
      c += 4;
    }
    return { positions, scales, colors };
  }

  animate() {
    requestAnimationFrame(this.animate.bind(this));

    // check resize
    const resize = this.checkResize();
    if (resize) {
      this.resize();
    }

    const delta = this.clock.getDelta();

    const scales = this.particles.geometry.attributes.scale.array as any[];
    const colors = this.particles.geometry.attributes.colors.array;
    for (let i = 0; i < this.numParticles; i++) {
      scales[i] =
        Math.sin(this.sizeOffsets[i] + this.time) *
          colors[i * 4 + 3] *
          this.MAX_POINT_SIZE +
        this.MIN_POINT_SIZE;
    }

    this.particles.geometry.attributes.scale.needsUpdate = true;
    this.renderer.render(this.scene, this.camera);
    this.time += delta * this.RIPPLE_SPEED;
  }

  resize() {
    this.camera.aspect = this.heroCont.clientWidth / this.heroCont.clientHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(
      this.heroCont.clientWidth,
      this.heroCont.clientHeight
    );
    // reset particle postion
    if (this.heroCont.clientWidth > 550 && this.OFFSET_Y_HEIGHT === 300) {
      this.OFFSET_Y_HEIGHT = 200;
      this.particles.geometry.translate(0, -100, 0);
    } else if (
      this.heroCont.clientWidth <= 550 &&
      this.OFFSET_Y_HEIGHT === 200
    ) {
      this.OFFSET_Y_HEIGHT = 300;
      this.particles.geometry.translate(0, 100, 0);
    }
  }

  checkResize() {
    return (
      this.canvas.width !== this.canvas.clientWidth ||
      this.canvas.height !== this.canvas.clientHeight
    );
  }

  numMap(value, start1, stop1, start2, stop2) {
    return ((value - start1) / (stop1 - start1)) * (stop2 - start2) + start2;
  }
}
