export const FRAGMENT_SHADER = `
       uniform sampler2D spriteTexture;
       varying vec4 vColor;

             void main() {
if ( length( gl_PointCoord - vec2( 0.5, 0.5 ) ) > 0.475 ) discard;

      	gl_FragColor = vColor;
               

             }
             
    `;
